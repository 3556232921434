import React from 'react'
import WhoisModule from '../../Modules/WhoisModule/WhoisModule'

const WhoisPage = () => {
  return (
    <div>
      <WhoisModule/>
    </div>
  )
}

export default WhoisPage

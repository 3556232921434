import React from 'react'
import BusinessModule from '../../Modules/BusinessModule/BusinessModule'

const BusinessPage = () => {
  return (
     <BusinessModule/>
  )
}

export default BusinessPage

import React from "react";
import "./HomeIptvCard.scss";
import img from "../../../../Assets/Home/img/HomeIptv/image 19.png";
const HomeIptvCard = ({ activeTitleIptv }) => {
  return (
    <a href="#tarifs" className="a">
      <div className="homeIptvCard_block" onClick={()=> activeTitleIptv()}>
        <div className="homeIptvCard_item">
          <p>
            Любимые фильмы, программы и сериалы в отличном качестве и без
            рекламы
          </p>
          <img src={img} alt="" />
        </div>
      </div>
    </a>
  );
};

export default HomeIptvCard;

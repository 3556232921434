import img1 from '../../../Assets/Vacancies/img/metaverse_char_007.png';
import img2 from '../../../Assets/Vacancies/img/Ellipse 6.png';
import axios from 'axios';
import { url } from '../../url/url';
 

const valuesData = [
  {
    id: 1,
    img: img1,
    title: "Целеустремленность",
    description: "Мы ставим цели и всегда стремимся достичь их. Только ставя цели и достигая их компания может жить и развиваться. Мы планируем свою деятельность и отчитываемся о выполнении и невыполнении намеченных планов."
  },
  {
    id: 2,
    img: img1,
    title: "Ответственность",
    description: "Мы ответственно относимся к своим коллегам, к нашим клиентам к общению и взаимодействию с ними. Мы берём на себя ответственность за каждое своё слово, дело, действие или бездействие. Мы (каждый из нас) берём на себя ответственность за свою работу, свои задачи, свои решения. Мы берём на себя ответственность за отношения с коллегами и атмосферу в коллективе."
  },
  {
    id: 3,
    img: img1,
    title: "Саморазвитие",
    description: "Нам важно расти как в личном, так и в профессиональном плане. Важно постоянно меняться, развиваться и учиться новому, новым технологиям. Мы верим, что у каждого сотрудника потенциал намного выше, чем он предполагает. Наша цель — помочь этому потенциалу раскрыться."
  },
  {
    id: 3,
    img: img1,
    title: "Уважение",
    description: "Мы признаем ценность каждого человека в компании, уважаем его труд, время, свободу и собственное мнение. Уважение к нашим деловым партнерам дает нам возможность успешно сотрудничать, сохраняя доверие и открытость."
  },
  {
    id: 3,
    img: img1,
    title: "Доверие",
    description: "Мы доверяем друг другу и дорожим доверием коллег. Мы исходим из добросовестности, искренности помыслов коллег. Мы своевременно выполняем взятые на себя обязательства, заранее принимаем меры к уменьшению последствий невыполненных обязательств."
  },
 
];

export default valuesData;

export const sliderData = [
    {
        img: img2,
        text: 'Моя история началась, когда я был техником бригады развития в подрядной организации и строил коммуникации для услуги «Интернет дома». Я хотел развиваться дальше и начал мониторить вакансии Beeline на сайте hh.kz. Увидел позицию «инженер по развитию сети», ознакомился с требованиями и решил подать резюме. Прошел собеседование и стажировку, начал работать и сам не заметил, как прошло уже три года! Очень приятно работать в инновационной компании, стратегии и ценности которой мне близки. За дружный коллектив — отдельное спасибо.',
        name: 'Ахметов Ануа',
        position: '- Инженер по развитию абонентской сети'
    },
    {
        img: img2,
        text: 'Моя история началась, когда я был техником бригады развития в подрядной организации и строил коммуникации для услуги «Интернет дома». Я хотел развиваться дальше и начал мониторить вакансии Beeline на сайте hh.kz. Увидел позицию «инженер по развитию сети», ознакомился с требованиями и решил подать резюме. Прошел собеседование и стажировку, начал работать и сам не заметил, как прошло уже три года! Очень приятно работать в инновационной компании, стратегии и ценности которой мне близки. За дружный коллектив — отдельное спасибо.',
        name: 'Ахметов Ануа',
        position: '- Инженер по развитию абонентской сети'
    },
    {
        img: img2,
        text: 'Моя история началась, когда я был техником бригады развития в подрядной организации и строил коммуникации для услуги «Интернет дома». Я хотел развиваться дальше и начал мониторить вакансии Beeline на сайте hh.kz. Увидел позицию «инженер по развитию сети», ознакомился с требованиями и решил подать резюме. Прошел собеседование и стажировку, начал работать и сам не заметил, как прошло уже три года! Очень приятно работать в инновационной компании, стратегии и ценности которой мне близки. За дружный коллектив — отдельное спасибо.',
        name: 'Ахметов Ануа',
        position: '- Инженер по развитию абонентской сети'
    },
]

export const vacancies = async () => {
  return await axios.get(`${url}vacancy/`)
}
import React from 'react'
import HostingModule from '../../Modules/HostingModule/HostingModule'

const HostingPage = () => {
  return (
    <div>
      <HostingModule/>
    </div>
  )
}

export default HostingPage

import React from 'react'
import HomeModule from '../../Modules/HomeModule/HomeModule'

const HomePage = () => {
  return (
     <HomeModule/>
  )
}

export default HomePage

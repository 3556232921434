import React from 'react'
import './VacanciesCards.scss'
import VacanciesCard from './VacanciesCard/VacanciesCard'

const VacanciesCards = ({vacanciesData}) => {
//   {
//     "id": 1,
//     "title": "Оператор",
//     "description": "описание вакансиии",
//     "country": "Душанбе",
//     "image": "http://192.168.2.149:8001/vacancy/imgs/metaverse_char_004_1_1.png",
//     "requirements": [
//         {
//             "id": 1,
//             "name": "Город",
//             "image": "http://192.168.2.149:8001/vacancy/imgs/IMAGE_7.png",
//             "text": "Душанбе"
//         },
//         {
//             "id": 2,
//             "name": "Опыт работы",
//             "image": "http://192.168.2.149:8001/vacancy/imgs/IMAGE_8.png",
//             "text": "Опыт работы не требуется"
//         },
//         {
//             "id": 3,
//             "name": "График работы",
//             "image": "http://192.168.2.149:8001/vacancy/imgs/IMAGE_9.png",
//             "text": "Не требуется"
//         },
//         {
//             "id": 4,
//             "name": "З/П",
//             "image": "http://192.168.2.149:8001/vacancy/imgs/IMAGE_10.png",
//             "text": "Договорная"
//         }
//     ],
//     "content": [
//         {
//             "id": 1,
//             "name": "Что нужно делать",
//             "text": "В команде с владельцем продукта анализировать данные о поведении пользователей, воронки, пути, сегменты, находить проблемные места и точки роста\r\nСоздавать продуктовые дашборды (в Superset) для отслеживания показателей ресурса\r\nГенерировать и проверять гипотезы, проводить A/B тесты и заниматься оценкой результатов изменений продукта\r\nРазрабатывать и вести систему метрик под архитектуру сайта/приложения для оценки бизнес показателей и качества взаимодействия пользователей с ресурсом\r\nМониторить ключевые метрики, искать причины изменений и аномалий."
//         },
//         {
//             "id": 2,
//             "name": "Кого мы ищем",
//             "text": "Уверенно знаешь SQL, работал с базами данных, умеешь объединять данные из разных источников\r\nРаботал с сырыми данными в BigQuery, ClickHouse и т.д.\r\nПонимаешь unit-экономику и продуктовые метрики\r\nУмеешь проводить A/B тесты и оценивать результаты\r\nОпыт анализа реальных данных на Python\r\nИмеешь практический опыт создания дашбордов с помощью BI инструментов (Superset / Power BI / Tableau / Redash и т.д.)\r\nВладеешь инструментами web/app аналитики (Яндекс Метрика / Google Analytics / AppMetrica / AppsFlyer)."
//         },
//         {
//             "id": 3,
//             "name": "Что предлагаем",
//             "text": "Профессиональные гильдии инженеров по направлениям, чтобы поддерживать друг друга и обмениваться опытом <br/>\r\nПолезные курсы и вебинары в корпоративном университете и электронные библиотеки <br/>\r\nПолезные курсы и вебинары в корпоративном университете и электронные библиотеки. Доступ к сервису «Понимаю»: онлайн-консультации с психологом, юристом, экспертом по финансам или ЗОЖ <br/>\r\nКорпоративный и командный психолог в офисе и массажный кабинет <br/>\r\nЕдиную подписку МТС Premium — KION light в онлайн-кинотеатре KION, сервис МТС Music, 30 дней бесплатного пользования подпиской OZON Premium <br/>\r\nСкидки и предложения от партнеров на фитнес, занятия английским и прочее."
//         }
//     ]
// }
  return (
    <div className='vacanciesCards_container'>
       <div className="vacanciesCards_block">
        <div className="vacanciesCards_title">
            <h1>Актуальные вакансии</h1>
            <p>Мы ценим каждого, кто разделяет наши ценности</p>
        </div>
        <div className="vacanciesCards_item">
          {
            vacanciesData?.map((e)=> 
            
              <VacanciesCard vacanciesData={e} key={e.id}/>
            )
          } 
        </div>
       </div>
    </div>
  )
}

export default VacanciesCards

import React from 'react'
import NewsItemModule from '../../Modules/NewsItemModule/NewsItemModule'

const NewsItemPage = () => {
  return (
    <div>
      <NewsItemModule/>
    </div>
  )
}

export default NewsItemPage

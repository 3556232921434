import React from 'react'
import VacanciesModule from '../../Modules/VacanciesModule/VacanciesModule'

const VacanciesPage = () => {
  return (
     <VacanciesModule/>
  )
}

export default VacanciesPage

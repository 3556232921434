import React from 'react'
import './HomeConnectCard.scss'
import MyButton from '../../../../UI/MyButton/MyButton'
const HomeConnectCard = ({id, title, subTitle, active, setActive}) => {
  return (
    <div className='homeConnectCard_block'>
      <div className="homeConnectCard_number">
        <h2>{id}</h2>
      </div>
      <h3>{title}</h3>
      <p>{subTitle}</p>
      {
        active && 
      <div className="homeConnectCardBtn_block" onClick={()=> setActive(true)}>
        <MyButton name={'Оставьте заявку'} grey={true} width={'100%'} height={'40px'} size={'16px'}/>
      </div>
      }
    </div>
  )
}

export default HomeConnectCard

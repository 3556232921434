import React from 'react'
import DirectumModule from '../../Modules/DirectumModule/DirectumModule'

const DirectumPage = () => {
  return (
    <div>
      <DirectumModule/>
    </div>
  )
}

export default DirectumPage

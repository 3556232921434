import React, { useEffect, useState } from 'react'
import './TarifsCards.scss'
import TarifCard from './TarifCard/TarifCard'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import { NextArrow, PrevArrow } from './TarifCard/Arrows/Arrows';
import Form from '../../../General/Form/Form';
import TarifCustomCard from './TarifCustomCard/TarifCustomCard';
import RouterCards from '../../../Router/RouterSlider/RouterCards/RouterCards';
import RouterCard from '../../../Router/RouterSlider/RouterCards/RouterCard/RouterCard';
const TarifsCards = ({tarifCards, tarifIptvData,  tarifAdditionallyData,activeTitle,tarifYearCards}) => {
  
  const [showModal, setShowModal] = useState(false)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: activeTitle !== 2 ? 3 : 2,
    autoplay: activeTitle !== 2 ? true : false,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />, 
    responsive: [
      {
        breakpoint: 1280,  
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,  
          nextArrow: false,
          prevArrow: false, 
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1124,  
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,  
          nextArrow: false,
          prevArrow: false, 
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false, 
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: true, 
          arrows: false, 
        },
      },
    ],
  };
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));   
  }, []);
  return (
    <div className='tarifsCards_block'>
      <Slider {...settings} >
      
      {
        activeTitle === 0 ?
        tarifCards?.map((e)=> 
        <>
        <TarifCard tarifCards={e} show={setShowModal} key={e.id}/>
        </>
        ) : activeTitle === 1 ? 
        tarifYearCards?.map((e)=> 
          <>
          <TarifCard tarifCards={e} show={setShowModal} key={e.id} /> 
          </>
          ) :
          activeTitle === 2 ?
          tarifIptvData?.map((e) =>
            e.iptv ? (
              <RouterCard key={e.id} data={e} setActive={setShowModal} iptv={e.iptv} />
            ) : (
              <TarifCustomCard
                key={e.id} 
                img={e.img} 
                title={e.title} 
                subTitle={e.subTitle} 
                data={e.data} 
                price={e.price}
                show={setShowModal} 
              />
            )
          )
        
        
        : activeTitle === 3 ? 
        tarifAdditionallyData?.map((e)=> 
          <TarifCustomCard img={e.img} title={e.title} subTitle={e.subTitle} data={e.data} price={e.price} longText={e.longText} key={e.id} show={setShowModal}/>
         ) : <p></p>
      }


      </Slider>
      {
        showModal &&
      <div className="form_container">
        <div className="form_block">
          <Form show={showModal} setShowModal={setShowModal}/>
        </div>
      </div>
      }
    </div>
  )
}

export default TarifsCards

import img1 from '../../../Assets/Business/b-icon_connect-glob-big.svg.png'
import img2 from '../../../Assets/Business/wifi-icon.svg.png'
import img3 from '../../../Assets/Business/b-icon_connect-2-9.svg.png'

import imgInternetCard1 from '../../../Assets/Business/b-icon-small-business.svg.png'
import imgInternetCard2 from '../../../Assets/Business/b-icon-middle-business.svg.png'
import imgInternetCard3 from '../../../Assets/Business/b-icon-growing-business.svg.png'

import imgServicesCard1 from '../../../Assets/Business/b2b_widget_ic_corp_tarif_v3.png.png'
import imgServicesCard2 from '../../../Assets/Business/b2b_widget_puzzle4_v4.png.png'
import imgServicesCard3 from '../../../Assets/Business/b2b_widget_ic_business_service_v3.png.png'
import imgServicesCard4 from '../../../Assets/Business/b2b_widget_ic_cloud_v3 1.png'
import imgServicesCard5 from '../../../Assets/Business/b2b_chip 2.png'
import imgServicesCard6 from '../../../Assets/Business/b2b_widget_ic_wi_fi_v3.png.jpg'

import imgDiscount1 from '../../../Assets/Business/SVG.png'
import imgDiscount2 from '../../../Assets/Business/SVG (1).png'
import imgDiscount3 from '../../../Assets/Business/SVG (1).svg'
import imgDiscount4 from '../../../Assets/Business/SVG (2).png'


import imgFAQ1 from '../../../Assets/Business/client.svg.svg'
import imgFAQ3 from '../../../Assets/Business/area.svg.svg'





export const BusinessAdvantagesData = [
    {id: 1, img: img1, text: 'Скоростное соединение с интернетом'},
    {id: 2, img: img2, text: 'Качественный роутер в аренду с установкой под ключ'},
    {id: 3, img: img3, text: 'Бесперебойная работа ресурсоёмких сервисов'},
]
export const BusinessInsternetData = [
    {id: 1, img: imgInternetCard1, title: '20 Мбит/с', text: 'Малый бизнес, небольшой офис с несколькими сотрудниками'},
    {id: 2, img: imgInternetCard2, title: '50 Мбит/с', text: 'Крупный бизнес с большой командой'},
    {id: 3, img: imgInternetCard3, title: '100 Мбит/с', text: 'Крупный бизнес с большой командой и ресурсоёмкими сервисами'},
    {id: 4, img: imgInternetCard3, title: '200 Мбит/с', text: 'Корпорации с высоконагруженными системами'},
]
export const BusinessServicesData = [
    {id: 1, img: imgServicesCard1, title: 'Битрикс', text: '«Умный бизнес»: безлимитные звонки, гибкая настройка и полезные опции. Выберите оптимальный тариф', link: 'https://bx24.nets.tj/'}, 
    {id: 2, img: imgServicesCard2, title: 'Директум', text: 'ПО, оборудование и облачные решения от российских производителей для безопасной и стабильной работы бизнеса', link: '/directum'}, 
    {id: 3, img: imgServicesCard3, title: 'Антивирусы', text: 'Управляйте корпоративной связью своих сотрудников. Контролируйте расходы, анализируйте отчёты, подключайте услуги', noneText: true}, 
    {id: 4, img: imgServicesCard4, title: 'Облачные решения ', text: 'Защитите бизнес от кибератак с помощью решений и сервисов информационной безопасности', link: '/hosting'}, 
    {id: 5, img: imgServicesCard5, title: 'СКС', text: 'Управляйте корпоративной связью своих сотрудников. Контролируйте расходы, анализируйте отчёты, подключайте услуги', link: '/system'}, 
    {id: 6, img: imgServicesCard6, title: 'Решение Wi-Fi для бизнеса', text: 'WiFi для торговых центров и магазинов', link:'/business#internet', active: true}, 
]
export const BusinessDiscountData = [
    {id: 1, img: imgDiscount1, title: 'Акции'}, 
    {id: 2, img: imgDiscount2, title: 'Личный кабинет'}, 
    {id: 3, img: imgDiscount3, title: 'Выбрать оборудование'}, 
    {id: 4, img: imgDiscount4, title: 'Обратиться в поддержку'}, 
]
export const BusinessFAQData = [
    {id: 1, img: imgFAQ1, title: 'Хотите стать клиентом Nets?', subTitle: 'Закажите обратный звонок', active: true},  
    {id: 2, img: imgFAQ1, title: 'Услуга подключена, остались вопросы?', subTitle: 'Свяжитесь с нами'},  
    {id: 3, img: imgFAQ3, title: 'Адреса офисов:', subTitle: 'Посмотреть на карте', active: true},  
]
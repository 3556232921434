import React from "react";
import "./WhatWhois.scss";

const WhatWhois = () => {
  return (
    <div className="whatWhois_block">
      <h1>Что такое Whois сервис</h1>
      <div className="whatWhois_item">
        <h4>
          Whois-сервис (название от англ. who is – «кто это») предоставляет
          сведения о зарегистрированных доменах. Он бесплатный и доступен
          каждому без регистрации.
        </h4>
        <div className="whatWhoisText_block">
            <p>С помощью Whois-сервиса вы можете:</p>
            <ul>
                <li>Узнать владельца домена и его контакты.</li>
                <li>Проверить занятость домена.</li>
                <li>Определить регистратора домена – компанию, которая его зарегистрировала.</li>
                <li>Выяснить, на каком DNS-сервере расположен домен, чтобы, к примеру, узнать, на каком хостинге размещен интересующий сайт.</li>
                <li>Узнать дату регистрации и дату окончания регистрации домена.</li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default WhatWhois;

import React from "react";
import "./WhoisTitle.scss";
const WhoisTitle = () => {
  return (
    <div className="whoisTitle_block">
      <h1>Whois-проверка домена</h1>
      <p>
        Whois-сервис позволяет узнать, когда был зарегистрирован домен и на
        какой срок, кто его регистратор, а кто администратор
      </p>
    </div>
  );
};

export default WhoisTitle;

import React from 'react'
import NewsModule from '../../Modules/NewsModule/NewsModule'

const NewsPage = () => {
  return (
    <div>
      <NewsModule/>
    </div>
  )
}

export default NewsPage

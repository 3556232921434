import React from 'react'
import VideoModule from '../../Modules/VideoModule/VideoModule'

const VideoPage = () => {
  return (
    <div>
      <VideoModule/>
    </div>
  )
}

export default VideoPage

import React from 'react'
import './FooterText.scss'
const FooterText = () => {
  return (
    <div className='footerText_block'>
      <div className="footerText_item">
      <p>© 2024 ООО "Нет Солюшенс" Все права защищены</p> 
        <p>NETS - национальный интернет сервис провайдер Предоставляет комплексные решения для частных пользователей, бизнеса и государства.</p> 
      </div>
    </div>
  )
}

export default FooterText

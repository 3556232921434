import React from 'react'
import RouterModule from '../../Modules/RouterModule/RouterModule'

const RouterPage = () => {
  return (
    <div>
      <RouterModule/>
    </div>
  )
}

export default RouterPage

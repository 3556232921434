import React from 'react';
import './NewsItemBanner.scss';
import { FaWhatsapp } from "react-icons/fa6";
import { FaViber } from "react-icons/fa6";
import { RiTelegramLine } from "react-icons/ri";
import { IoLogoVk } from "react-icons/io";
import { FaOdnoklassniki } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';

const NewsItemBanner = ({ img, title }) => {
  const location = useLocation();
  const newsUrl = window.location.origin + location.pathname; // Полный текущий URL страницы

  const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(newsUrl)}`;
  const viberLink = `viber://forward?text=${encodeURIComponent(title)}%20${encodeURIComponent(newsUrl)}`;
  const telegramLink = `https://t.me/share/url?url=${encodeURIComponent(newsUrl)}&text=${encodeURIComponent(title)}`;
  const vkLink = `https://vk.com/share.php?url=${encodeURIComponent(newsUrl)}&title=${encodeURIComponent(title)}`;
  const odnoklassnikiLink = `https://connect.ok.ru/offer?url=${encodeURIComponent(newsUrl)}&title=${encodeURIComponent(title)}`;

  return (
    <div className='newsItemBanner_block'>
      <div className="newsItemBannerSocial_block">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className='newsItemBannerSocial_icon' />
        </a>
        <a href={viberLink} target="_blank" rel="noopener noreferrer">
          <FaViber className='newsItemBannerSocial_icon' />
        </a>
        <a href={telegramLink} target="_blank" rel="noopener noreferrer">
          <RiTelegramLine className='newsItemBannerSocial_icon' />
        </a>
        <a href={vkLink} target="_blank" rel="noopener noreferrer">
          <IoLogoVk className='newsItemBannerSocial_icon' />
        </a>
        <a href={odnoklassnikiLink} target="_blank" rel="noopener noreferrer">
          <FaOdnoklassniki className='newsItemBannerSocial_icon' />
        </a>
      </div>
      <img src={img} alt="News Item" />
    </div>
  );
};

export default NewsItemBanner;

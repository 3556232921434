import React from 'react'
import './FooterLinkMenu.scss'
import { NavLink } from 'react-router-dom'
const FooterLinkMenu = () => {
  return (
    <div className='footerLinkMenu_block'>
      <NavLink to={'/'}>Для дома</NavLink>
      <NavLink to={'/business'}>Бизнесу</NavLink>
      <NavLink to={'/system'}>Системные интеграции</NavLink>
      <NavLink to={'/vacancies'}>Вакансии</NavLink>
      {/* <NavLink to={'/'}>О компании</NavLink>  */}
    </div>
  )
}

export default FooterLinkMenu

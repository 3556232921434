import React from "react";
import "./NewsTitle.scss";
const NewsTitle = () => {
  return (
    <div className="newsTitle_block">
      <h1>Новости и Акции</h1>
      <p>
        Блог о современных технологиях для бизнеса и частных лиц. Цифровые
        продукты и решения для простых пользователей и профессионалов.
      </p>
    </div>
  );
};

export default NewsTitle;

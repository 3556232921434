import React from "react";
import "./BusinessServicesCard.scss";
import { NavLink } from "react-router-dom";
const BusinessServicesCard = ({ img, title, text, link, active, noneText }) => {
  return (
    <NavLink to={link} className={'businessServicesCard_link'}>
      <div className="businessServicesCard_block">
        <div className="businessServicesCard_item">
          <h3>{title}</h3>
          <p>{text}</p>
          {noneText ? (
            <></>
          ) : (
            <>
              {active === true ? (
                <a href={link}>Подробнее</a>
              ) : (
                <h4>Подробнее</h4>
              )}
            </>
          )}
        </div>
        <img src={img} alt="" />
      </div>
    </NavLink>
  );
};

export default BusinessServicesCard;

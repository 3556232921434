import React from "react";
import "./Sks.scss";

import HomeGeneralSlider from "../Home/HomeGeneralSlider/HomeGeneralSlider";
import SksAdvantages from "./SksAdvantages/SksAdvantages";
import SksTasks from "./SksTasks/SksTasks";
import SksProduct from "./SksProduct/SksProduct";

const Sks = ({setModal}) => {
  
  const sksAdvantagesData = [
    {id: 1, number: '01', title: 'Все направления IT', subTitle: 'В числе услуг — разработка и поддержка программных продуктов, внедрение IT-решений, поставка и обслуживание оборудования, обеспечение информационной безопасности, консультации и многое другое.'},
    {id: 2, number: '02', title: 'Решения под ключ', subTitle: 'Выполняем задачи от начала и до конца — внедряем и поддерживаем комплексные решения. Нам поручают сопровождение запуска и эксплуатации нового продукта или передачу на аутсорс поддержку инфраструктуры, ПО и оборудования.'},
    {id: 3, number: '03', title: 'Гибкость в работе', subTitle: 'Работаем с инфраструктурой любой сложности, разрабатываем оптимальные решения даже для нестандартных бизнес-задач. Применяем как коробочные, так и кастомизированные решения. При необходимости привлекаем лучших и проверенных технологических партнеров на рынке.'},
    {id: 4, number: '04', title: 'Опыт и экспертиза', subTitle: 'Реализовали собственные телеком- и IT-решения для 300 000 клиентов. Партнеры Нетворк Солюшен входят в топ-100 поставщиков IT-аутсорсинга в мире и в топ-5 по Таджикистану. Собственные Innovation Lab и B2B Tech Lab позволяют привлекать лучшую цифровую экспертизу для IT-проектов.'},
  ]
  const sksTasksData = [
    {id: 1, title: 'Система видеонаблюдения', subTitle: 'Установим камеры в ключевых местах, настроим мониторинг для оперативного контроля. Ваша безопасность под полным контролем.', active: true},
    {id: 2, title: 'Охранная сигнализация', subTitle: 'Изучим план здания, выберем оптимальные места для размещения датчиков и настроим систему так, чтобы она моментально оповещала вас и службы безопасности при малейшей угрозе. Надежная защита от нежелательных гостей.'},
    {id: 3, title: 'Акустические системы', subTitle: 'Исследуем акустические характеристики помещения, установим оборудование, обеспечивающее идеальную звуковую атмосферу для вашего бизнеса. Кристально чистый звук для любых задач.'},
    {id: 4, title: 'Система газового пожаротушения', subTitle: 'Исследуем специфику помещения и установим систему, которая эффективно предотвратит распространение огня с минимальными последствиями для оборудования и людей. Ваша техника и данные останутся в безопасности даже в случае ЧП.'},
    {id: 5, title: 'Пожарная сигнализация', subTitle: 'Проанализируем риски возгорания и интегрируем систему раннего оповещения, которая мгновенно активирует аварийные протоколы. Заблаговременное предупреждение — залог безопасности.'},
    {id: 6, title: 'Локальная вычислительная сеть', subTitle: 'Спроектируем и развернем надежную сеть, обеспечивающую стабильную и быструю передачу данных внутри вашей компании. Ваши данные будут летать по сети с молниеносной скоростью.'},
    {id: 7, title: 'Видео-концеренц связь', subTitle: 'Изучим потребности вашей компании и установим систему видеоконференций, которая обеспечит вам кристально чистую картинку и звук даже при больших расстояниях. Ваши совещания будут проходить так, будто все находятся в одной комнате.'},
    {id: 8, title: 'LED - экраны', subTitle: 'Исследуем возможности помещения и установим LED-экраны, которые обеспечат яркость и четкость изображения в любых условиях. Ваша информация будет всегда на виду и в самом выгодном свете.'},
    {id: 9, title: 'Система синхронного перевода', subTitle: 'Проанализируем ваши потребности в переводе и установим систему, которая обеспечит синхронный перевод на любые языки, чтобы ваши международные мероприятия проходили без языковых барьеров. Каждое слово будет услышано и понято.'},
    {id: 10, title: 'Система видеонаблюдения', subTitle: 'Установим камеры в ключевых местах, настроим мониторинг для оперативного контроля. Ваша безопасность под полным контролем.', active: true},
  ]
  const sksProduct = [
    {id: 1, number: '01', title: 'Автоматизируйте бизнес- процессы', subTitle: 'Упростите контроль рутинных процессов — например, обработку запросов в IT-службу или учет рабочих задач.'},
    {id: 1, number: '02', title: 'Улучшите ПО и инфраструктуру', subTitle: 'Повысьте производительность оборудования и ПО, запустите новые сервисы для масштабирования бизнеса.'},
    {id: 1, number: '03', title: 'Защитите бизнес от угроз', subTitle: 'Подключите защиту IT- инфраструктуры от вредоносных программ, спама, утечек информации и хакерских атак.'},
    {id: 1, number: '04', title: 'Займитесь бизнесом', subTitle: 'Передайте нам обслуживание оборудования и ПО, чтобы не отвлекаться от более важных задач в компании и сократить расходы на IT- отдел.'},
  ]
  return (
    <div className="sks_container">
      <div className="sks_block">
        <SksAdvantages data={sksAdvantagesData}/>
        <SksTasks data={sksTasksData}/>
        <SksProduct data={sksProduct} setModal={setModal}/>
      </div>
    </div>
  );
};

export default Sks;

import React from 'react'
import SksModule from '../../Modules/SksModule/SksModule'

const SksPage = () => {
  return (
    <div>
      <SksModule/>
    </div>
  )
}

export default SksPage



export const menuRef = [
    {
        id: 1,
        path: '/', 
        name: 'Для дома',
        

    },
    {
        id: 2,
        path: '/business',
        name: 'Бизнесу', 
    },
    // {
    //     id: 3,
    //     path: '/system',
    //     name: 'Системная интеграция', 
       
    // },
    {
        id: 4,
        path: '/whois',
        name: 'Домен', 
        
    },
    {
        id: 5,
        path: '/vacancies',
        name: 'Вакансии', 
    },
    
]

export const menuRefItem = [
    {
        id: 4,
        name: 'Ещё', 
        menuItemRef: [
            {
                id: 1,
                path: '/system',
                name: 'Системная интеграция', 
                
            },
            {
                id: 2,
                path: '/vacancies',
                name: 'Вакансии', 
            },
        ]
    }
]